import React, {useContext} from 'react'
import RedesSociales from './RedesSociales'
import {TEXTO_VISUAL, FACTURACION} from '../../constantes'
import {OpcionLateral} from './opcionLateral/OpcionLateral'
import {aplicarFormatoMoneda} from '../../utils/aplicarFormatoMoneda'
import {ContextoAplicacion} from '../../contexto'
import {Linkedin, Youtube, Instagram} from '../../imagenes/redesSociales'
import moment from 'moment'
import {URL_APP} from '../../constantes'
import {useObtenerInformacion} from '../../hooks/useInformacion'
import {GenerarCertificadoCustodiaClinicaPorEmpresaId} from '../../microservicios'
import clsx from 'clsx'

export const BarraLateral = ({datosAtenciones, saldoDisponible}) => {
  const contexto = useContext(ContextoAplicacion)
  const {city} = useObtenerInformacion()
  const empresaId = city

  const redesSociales = [
    {
      nombre: 'Linkedin',
      enlace: 'https://www.linkedin.com/company/cendiatra/',
      logo: Linkedin,
    },
    {
      nombre: 'Instagram',
      enlace: 'https://www.instagram.com/cendiatracolombia/',
      logo: Instagram,
    },
    {
      nombre: 'Youtube',
      enlace: 'https://www.youtube.com/@cendiatra_sas',
      logo: Youtube,
    },
  ]

  const abrirManualUsuario = () => {
    window.open(URL_APP.MANUAL_USUARIO)
  }

  const generarCertificadoCustodiaPorEmpresaId = async () => {
    if (!empresaId) return

    const respuesta = await GenerarCertificadoCustodiaClinicaPorEmpresaId(
      empresaId
    )

    if (respuesta.status === 200) window.open(respuesta.data)
  }

  const mostrarInformacionSaldo =
    (contexto.infoEmpresa?.tipoFacturacion ?? '').toLowerCase() ===
    FACTURACION.CONTADO

  return (
    <div
      className={clsx(
        'w-18% flex flex-wrap justify-center items-center content-between rounded-lg bg-cendiatra-gris-5 shadow-xl px-6',
        {
          'max-h-screen': mostrarInformacionSaldo,
          'h-full': !mostrarInformacionSaldo,
        }
      )}
    >
      <div className="w-10/12 flex flex-wrap justify-center items-start text-start  mt-38px">
        <span className="w-full text-cendiatra-verde-2 text-17px font-helveticaNegrita border-b border-gray-500 pb-16px uppercase">
          {TEXTO_VISUAL.HOME.LATERAL.NOMBRE_APLICACION}
        </span>
        <span
          className="w-full text-cendiatra-verde-2 text-17px font-bold pt-16px uppercase mb-5"
          style={{lineHeight: '19px'}}
        >
          {TEXTO_VISUAL.HOME.LATERAL.BIENVENIDA}
          <br />
          <span className="w-full text-cendiatra-verde-3 text-17px font-bold ">
            {contexto.infoEmpresa.nombre}
          </span>
        </span>
        <span className="flex justify-start font-bold text-cendiatra-verde-2 text-17px w-full">
          {TEXTO_VISUAL.HOME.LATERAL.MES_ACTUAL}
        </span>
        <div className="w-full flex flex-wrap justify-between items-start text-center py-2">
          <div className="w-5/12 flex flex-wrap justify-center items-start ">
            <span className="w-full text-center text-cendiatra-verde-3 font-helveticaNegrita h-14 text-45px font-bold">
              {datosAtenciones.pacientesAtendidos}
            </span>
            <span
              className="w-full text-center text-cendiatra-gris-2 text-13px font-bold"
              style={{lineHeight: '15px'}}
            >
              {TEXTO_VISUAL.HOME.LATERAL.ORDENES_READAS}
            </span>
          </div>
          <div className="border-l-2 h-10"></div>
          <div className="w-6/12 flex flex-wrap justify-center items-center">
            <span className="w-full text-center text-cendiatra-verde-3 font-helveticaNegrita h-14 text-45px font-bold">
              {datosAtenciones.ordenesServicioCreadas}
            </span>
            <span
              className="w-full text-center text-cendiatra-gris-2 text-13px font-bold"
              style={{lineHeight: '15px'}}
            >
              {TEXTO_VISUAL.HOME.LATERAL.CERTIFICADOS_PENDIENTES_DESCARGA}
            </span>
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-between items-start text-center py-2">
          <div className="w-5/12 flex flex-wrap justify-center items-start ">
            <span className="w-full text-center text-cendiatra-verde-3 font-helveticaNegrita h-14 text-45px font-bold">
              {datosAtenciones.pacientesConOrden}
            </span>
            <span
              className="w-full text-center text-cendiatra-gris-2 text-13px font-bold"
              style={{lineHeight: '15px'}}
            >
              {TEXTO_VISUAL.HOME.LATERAL.PACIENTE_CON_ORDEN}
            </span>
          </div>
          <div className="border-l-2 h-14"></div>
          <div className="w-6/12 flex flex-wrap justify-center items-center">
            <span className="w-full text-center text-cendiatra-verde-3 font-helveticaNegrita h-14 text-45px font-bold">
              {datosAtenciones.pacientesSinOrden}
            </span>
            <span
              className="w-full text-center text-cendiatra-gris-2 text-13px font-bold"
              style={{lineHeight: '15px'}}
            >
              {TEXTO_VISUAL.HOME.LATERAL.PACIENTE_SIN_ORDEN}
            </span>
          </div>
        </div>
        <div className="border-b border-cendiatra-gris-4 w-full pt-5 "></div>
        {mostrarInformacionSaldo && (
          <>
            <div className="w-full flex flex-wrap text-start px-3 pt-5">
              <p className="flex justify-start font-bold text-cendiatra-verde-2 text-17px w-full">
                {TEXTO_VISUAL.HOME.LATERAL.SALDO_DISPONIBLE}
              </p>
              <p
                className={clsx(
                  'w-full text-start font-helveticaNegrita h-10 text-35px font-bold mt-2',
                  saldoDisponible?.saldo < 0
                    ? 'text-cendiatra-rojo-1'
                    : 'text-cendiatra-verde-3'
                )}
              >
                {aplicarFormatoMoneda(saldoDisponible.saldo ?? '')}
              </p>
              <p className="text-cendiatra-verde-3 mt-4 font-bold text-sm">
                {TEXTO_VISUAL.HOME.LATERAL.ULTIMA_RECARGA}
              </p>
              <p className="text-cendiatra-verde-3 mt-2 text-sm">
                {' '}
                {moment(saldoDisponible.fechaUltimaRecarga ?? '').format(
                  'YYYY-MM-DD'
                )}{' '}
                -{' '}
                {aplicarFormatoMoneda(saldoDisponible.valorUltimaRecarga ?? '')}
              </p>
              <a
                className="text-cendiatra-verde-3 mt-2 underline font-bold text-sm pb-5"
                href="/verdetalleymovimiento"
              >
                {TEXTO_VISUAL.HOME.LATERAL.VER_MOVIMIENTOS_Y_DETALLES}
              </a>
            </div>

            <div className="border-b border-cendiatra-gris-4 w-full "></div>
          </>
        )}
        <div className="w-full flex flex-wrap justify-center items-center py-2">
          <OpcionLateral
            texto={TEXTO_VISUAL.HOME.LATERAL.OPCIONES.DESCARGAR_MANUAL_USUARIO}
            onOpcionClick={abrirManualUsuario}
          />
          <OpcionLateral
            texto={
              TEXTO_VISUAL.HOME.LATERAL.OPCIONES
                .CERTIFICACION_DE_CUSTODIA_DE_HISTORIA_CLINICA
            }
            onOpcionClick={generarCertificadoCustodiaPorEmpresaId}
          />
        </div>
        <div className="border-b border-cendiatra-gris-4 w-full"></div>
      </div>

      <RedesSociales
        estilosContenedor={
          'w-full flex flex-wrap font-bold justify-start items-center pb-2'
        }
        titulo={TEXTO_VISUAL.HOME.LATERAL.SIGUENOS}
        redes={redesSociales}
      />
    </div>
  )
}
