import {useState} from 'react'
import HojaInformacion from '../../imagenes/HojaInformacion.svg'
import {CeldaDobleTexto} from '../Tablas'
import {Tooltip} from '../Tooltip'
import {BotonConImagen} from '../Botones'
import expandir from '../../imagenes/Expandible/flechaDesplegableExpandirVerde.svg'
import expandirBlanco from '../../imagenes/Expandible/flechaDesplegableExpandirBlanco.svg'
import {ESTADOS_ATENCION_CONSTANTES} from '../../constantes'
const CeldaInformacionOrdenYUsuario = ({
  datosUsuario,
  informacionTabla,
  modificarDatosTabla,
}) => {
  const [mostrarTooltip, setMostrarTooltip] = useState(false)

  const mostrarOEsconderPrecios = (posicion) => {
    if (posicion !== datosUsuario.atencionId) return

    const datosMapeados = informacionTabla.map((atencion) => {
      if (atencion.atencionId === posicion) {
        return {
          ...atencion,
          filaSeleccionada: !atencion.filaSeleccionada,
        }
      } else {
        return {
          ...atencion,
          filaSeleccionada: false,
        }
      }
    })
    modificarDatosTabla(datosMapeados)
  }
  return (
    <div className="w-full flex justify-start items-center">
      <div className="w-2/12">
        {datosUsuario.servicios &&
        datosUsuario.estadoAtencion !==
          ESTADOS_ATENCION_CONSTANTES.EN_PROCESO ? (
          <BotonConImagen
            estilosImagen={'w-18'}
            imagen={expandir}
            medio={true}
            funcionAEjecutar={() =>
              mostrarOEsconderPrecios(datosUsuario.atencionId)
            }
            rotacion={datosUsuario.filaSeleccionada ? '180' : '0'}
            textoAlternativo={'expandir'}
          />
        ) : (
          <BotonConImagen
            estilosImagen={'w-18'}
            imagen={expandirBlanco}
            medio={true}
            funcionAEjecutar={() => null}
            textoAlternativo={'expandir'}
          />
        )}
      </div>
      <div className="w-2/12 ">
        <div
          className={`w-full  justify-center items-center ${
            datosUsuario?.idOrdenPortalCliente !== 0 ? 'flex' : 'hidden'
          }`}
        >
          <Tooltip
            tooltip={
              <div className="w-full flex flex-wrap justify-evenly px-2">
                <span className="w-full flex">
                  Número de orden: {datosUsuario?.idOrdenPortalCliente ?? ''}
                </span>
                <span className="w-full flex">
                  Fecha de orden:{' '}
                  {datosUsuario?.numeroOrdenyFechaCreacion?.Fila2 ?? ''}
                </span>
              </div>
            }
            activo={mostrarTooltip}
          >
            <button
              className="w-full flex justify-center items-center"
              onMouseEnter={() => setMostrarTooltip(true)}
              onMouseLeave={() => setMostrarTooltip(false)}
            >
              <img
                src={HojaInformacion}
                alt="informacioToolTip"
                className="w-12 h-9"
              />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="w-7/12 flex items-center">
        <CeldaDobleTexto value={datosUsuario?.nombresyDocumento ?? {}} />
      </div>
    </div>
  )
}

export default CeldaInformacionOrdenYUsuario
