import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useTable, usePagination, useRowSelect, useFilters} from 'react-table'
import {CheckboxSeleccionar} from '../inputs'
import {FiltroTexto} from '../Tablas'
import {BotonSimple} from '../Botones'
import {ESTADOS_ATENCION_CONSTANTES, TEXTO_VISUAL} from '../../constantes'
import TablaConcepto from './TablaConcepto'

const TablaOrdenes = ({
  data,
  columns,
  usarSelected = true,
  funcionSeleccion,
  children,
  limpiarTodosLosFiltros,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      dobleTexto: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue.Fila1)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase()) ||
                String(rowValue.Fila2)
                  .toLowerCase()
                  .includes(String(filterValue).toLowerCase())
            : true
        })
      },
      fecha: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? moment(rowValue).format('DD/MM/YYYY') ==
                moment(filterValue).format('DD/MM/YYYY')
            : true
        })
      },
      lista: (rows, id, filterValue) => {
        if (!filterValue) return rows
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined ? rowValue == filterValue : false
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: FiltroTexto,
    }),
    []
  )

  const selectedRowsChangedKey = useRef(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: {filters},
    setAllFilters,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {pageIndex: 0},
      stateReducer: (newState, action) => {
        if (action.type === 'toggleRowSelected') {
          newState.selectedRowIds = {
            ...newState.selectedRowIds,
            [action.id]: newState.selectedRowIds[action.id],
          }
        }
        if (
          ['toggleRowSelected', 'toggleAllRowsSelected'].includes(action.type)
        ) {
          selectedRowsChangedKey.current = Date.now()
        }
        return newState
      },
    },
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'checkbox',
          className: `w-1/12 ${!usarSelected ? 'hidden' : ''}`,
          Header: ({getToggleAllRowsSelectedProps}) => (
            <div className="flex w-full flex-wrap justify-evenly items-center">
              <span className="w-full text-center my-2">
                {'Seleccionar todo'}
              </span>
              <div className="flex w-full justify-center items-center">
                <CheckboxSeleccionar {...getToggleAllRowsSelectedProps()} />
              </div>
            </div>
          ),
          Cell: ({row}) => (
            <div className="flex justify-center items-center">
              <CheckboxSeleccionar
                {...row.getToggleRowSelectedProps()}
                disabled={
                  row.original.estado !== TEXTO_VISUAL.ORDENES.ESTADO.ATENDIDA
                }
              />
            </div>
          ),
        },
      ])
    }
  )

  const limpiarFiltros = () => {
    setAllFilters([])
    resetDropdown('centroCostosEmpresaUsuaria')
    resetDropdown('tipoExamen')
    resetDropdown('estado')
    resetDropdown('concepto')
    resetDropdown('select')
    funcionSeleccion(false ? data : [])
  }

  const resetDropdown = (columnId) => {
    setFilter(columnId, '')
  }

  return (
    <>
      <table className="w-full" {...getTableProps()}>
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-20 rounded-10px"
          style={{lineHeight: '16px'}}
        >
          {headerGroups.map((headerGroup, key) => (
            <tr
              className="w-full flex justify-evenly items-center h-full p-3px"
              {...headerGroup.getHeaderGroupProps()}
              key={key}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.className} h-full bg-cendiatra-verde-3 flex flex-wrap justify-center items-center content-center  m-3px rounded-9px`,
                    },
                  ])}
                  key={index}
                >
                  <div
                    className="w-full text-14px flex justify-evenly items-center font-light"
                    style={{margin: '6px 0 '}}
                  >
                    {column.render('Header')}
                  </div>
                  <div
                    className="w-full flex justify-evenly items-center mb-2 text-13px px-1"
                    style={{margin: '6px 0'}}
                  >
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="w-full " {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <div>
                <tr
                  className="w-full border border-cendiatra text-cendiatra-gris-1 rounded-2xl flex justify-evenly items-center text-center text-13px my-3 py-1"
                  {...row.getRowProps()}
                  key={i}
                >
                  {row.cells.map((cell, key) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: `${cell.column.className} m-3px  p-3px`,
                        })}
                        key={key}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
                {row.original.filaSeleccionada &&
                row.original.servicios &&
                row.original.estadoAtencion !==
                  ESTADOS_ATENCION_CONSTANTES.EN_PROCESO ? (
                  <TablaConcepto servicios={row.original.servicios} />
                ) : null}
              </div>
            )
          })}
        </tbody>
      </table>
      <div className="w-full flex flex-wrap justify-center m-5 h-10 mb-20 ">
        {!data?.length ? (
          <div className="w-full flex justify-center items-start border border-cendiatra-verde-2 rounded-lg">
            <span className="text-cendiatra-gris-1 text-xl my-8">
              No hay resultados para la búsqueda establecida. Por favor, ingrese
              un número de documento, nombre, apellido, o establezca un rango de
              fechas para obtener resultados.
            </span>
          </div>
        ) : null}
        <div className="flex w-full justify-end">{children}</div>
        <div className="flex w-full justify-end -mt-16">
          <BotonSimple
            texto={'Limpiar filtros'}
            estilosBoton={
              false
                ? 'w-32 h-40px p-1.5 rounded-lg bg-cendiatra-gris-4 bg-cover bg-no-repeat bg-center text-white font-bold  text-14px mx-25px pointer-events-none	'
                : 'w-32 h-40px p-1.5 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px'
            }
            funcion={limpiarTodosLosFiltros}
            deshabilitado={Boolean(false)}
          />
        </div>
      </div>
    </>
  )
}

export default TablaOrdenes
