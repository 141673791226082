import React, {useState, useContext, useRef, useMemo} from 'react'
import {
  TablaOrdenes,
  CeldaInformacionOrdenYUsuario,
  HeaderModulosTablaOrdenes,
  CeldaSeleccionar,
  HeaderSeleccionarTabla,
} from '../Ordenes'
import {
  CeldaDobleTexto,
  CeldaModulos,
  PaginacionConBackend,
  ComponenteConjuntoDeFiltros,
} from '../Tablas'
import {
  encabezadosExportarAtenciones,
  encabezadosExportarOrdenes,
} from '../CSV/EncabezadosArchivos.js'
import {useHistory} from 'react-router-dom'
import {ContextoAplicacion} from '../../contexto'
import {ActualizarEstadoOrden} from '../../microservicios'
import {CSVLink} from 'react-csv'
import {TEXTO_VISUAL} from '../../constantes'
import {ModalConfirmacionEstandar} from '../Modales'
import IconoImprimirActivado from '../../imagenes/iconoImprimirVerde.svg'
import botonDescargar from '../../imagenes/botonDescargar.svg'
import JSZip from 'jszip'
import {saveAs} from 'file-saver'
import moment from 'moment'
import {useMsal} from '@azure/msal-react'

const ListadoOrdenesYAtenciones = ({
  mostrarAtenciones = false,
  funcionObtenerDatos = {},
  datosTabla,
  actualizarDatosTabla,
  filtrosExternos = [],
  funcionFiltrar,
  informacionPaginacion = {},
  funcionCambiarPagina = {},
  funcionLimpiarFiltros = {},
}) => {
  const [seleccionImprimir, setSeleccionImprimir] = useState([])
  const [seleccionExportar, setSeleccionExportar] = useState([])

  const {accounts} = useMsal()
  const rolUsuario = accounts[0].idTokenClaims?.country ?? ''
  const idEmpresa = accounts[0].idTokenClaims?.city ?? ''
  const csvLinkEl = useRef()
  const zip = new JSZip()

  const descargarPdfZip = (urls, nombrePdf) => {
    const nombreFormatoFecha = moment().format('DD/MM/YYYY hh:mm A')
    Promise.all(
      urls.map((url) => {
        return fetch(url).then((response) => response.arrayBuffer())
      })
    )
      .then((contents) => {
        contents.forEach((content, index) => {
          const pdf = `certificados_${nombrePdf[index].numeroDocumento}_${nombrePdf[index].atencionId}.pdf`
          zip.file(`${pdf}`, content)
        })
      })
      .then(() => {
        zip.generateAsync({type: 'blob'}).then((blob) => {
          saveAs(blob, `certificados ${nombreFormatoFecha}.zip`)
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const esSeleccionadoUnCheckbox = (informacion, tipo) => {
    let TIPO_GESTION = mostrarAtenciones ? 'atencionId' : 'id'

    if (tipo === 'descarga') {
      const index = seleccionExportar.findIndex(
        (seleccion) => seleccion?.[TIPO_GESTION] === informacion?.[TIPO_GESTION]
      )

      if (index >= 0) {
        setSeleccionExportar((selecciones) => [
          ...selecciones.filter(
            (seleccion) =>
              seleccion?.[TIPO_GESTION] !== informacion?.[TIPO_GESTION]
          ),
        ])
      } else {
        setSeleccionExportar((selecciones) => [...selecciones, informacion])
      }
    } else {
      const index = seleccionImprimir.findIndex(
        (seleccion) => seleccion?.[TIPO_GESTION] === informacion?.[TIPO_GESTION]
      )

      if (index >= 0) {
        setSeleccionImprimir((selecciones) => [
          ...selecciones.filter(
            (seleccion) =>
              seleccion?.[TIPO_GESTION] !== informacion?.[TIPO_GESTION]
          ),
        ])
      } else {
        setSeleccionImprimir((selecciones) => [...selecciones, informacion])
      }
    }
  }

  const filtrarDatosParaExportarOImprimir = (datosParaExportar, imprimir) => {
    let TIPO_GESTION = mostrarAtenciones
      ? TEXTO_VISUAL.ATENCIONES.ATENDIDA
      : TEXTO_VISUAL.ORDENES.ESTADO.ATENDIDA

    let PROPIEDAD_DATOS = mostrarAtenciones ? 'estadoAtencion' : 'estado'

    if (imprimir) {
      return datosParaExportar.filter(
        (seleccion) =>
          seleccion?.[PROPIEDAD_DATOS] === TIPO_GESTION &&
          seleccion.urlCertificadoAtencion !== null
      )
    }
    return mostrarAtenciones
      ? datosParaExportar.filter(
          (seleccion) => seleccion?.[PROPIEDAD_DATOS] === TIPO_GESTION
        )
      : datosParaExportar
  }

  const csvReport = useMemo(
    () => ({
      data: filtrarDatosParaExportarOImprimir(seleccionExportar),
      headers: mostrarAtenciones
        ? encabezadosExportarAtenciones
        : encabezadosExportarOrdenes,
      filename: 'Ordenes',
    }),
    [seleccionExportar]
  )

  const imprimirSeleccion = useMemo(
    () => ({
      data: filtrarDatosParaExportarOImprimir(seleccionImprimir),
    }),
    [seleccionImprimir]
  )

  const exportarCSVOrdenesSeleccionadas = () => {
    if (seleccionExportar.length > 0) {
      csvLinkEl.current.link.click()
    }
  }

  const columnasTabla = [
    {
      Header: 'Nombres y No. de documento',
      accessor: 'nombresyDocumento',
      Cell: (row) => (
        <CeldaInformacionOrdenYUsuario
          datosUsuario={row.row.original}
          informacionTabla={datosTabla}
          modificarDatosTabla={actualizarDatosTabla}
        />
      ),
      disableFilters: true,
      className: 'w-15%',
      atencion: true,
      orden: false,
    },
    {
      Header: 'Número de orden',
      accessor: 'id',
      className: 'w-1/12',
      atencion: false,
      orden: true,
      disableFilters: true,
    },
    {
      accessor: 'identificador',
      className: 'hidden',
      atencion: true,
      orden: true,
    },
    {
      Header: 'Fecha de creación de la orden',
      accessor: 'fechaCreacion',
      className: 'w-1/12',
      atencion: false,
      orden: true,
      disableFilters: true,
    },
    {
      Header: 'Nombres y No. de documento',
      accessor: 'nombresyDocumento',
      Cell: CeldaDobleTexto,
      disableFilters: true,
      className: 'w-2/12',
      atencion: false,
      orden: true,
    },

    {
      Header: 'Centro de Costos O Empresa Usuaria',
      accessor: 'centroCostosEmpresaUsuaria',
      className: mostrarAtenciones ? 'w-2/12' : 'w-3/12',
      atencion: true,
      disableFilters: true,
      orden: true,
    },
    {
      Header: 'Estado',
      accessor: 'estado',
      disableFilters: true,
      className: 'w-2/12',
      atencion: false,
      orden: true,
    },
    {
      Header: 'Estado',
      accessor: 'estadoAtencion',
      disableFilters: true,
      className: 'w-2/12',
      atencion: true,
      orden: false,
    },
    {
      Header: 'Sede y Fecha de atención',
      accessor: 'sedeyFechaAtencion',
      disableFilters: true,
      className: 'w-2/12',
      atencion: true,
      Cell: CeldaDobleTexto,
      orden: false,
    },
    {
      Header: () => (
        <HeaderModulosTablaOrdenes mostrarAtenciones={mostrarAtenciones} />
      ),
      accessor: 'modulos',
      Cell: (row) => (
        <CeldaModulos
          estado={
            mostrarAtenciones
              ? row.row.original.estadoAtencion
              : row.row.original.estado
          }
          funcionEditar={() => clickModuloEditar(row.row.original)}
          funcionBorrar={() => clickModulo(row.row.original)}
          funcionResultados={() => clickModuloVer(row.row.original)}
          funcionImprimir={() =>
            clickModuloimprimirUnicoDocumento(
              row.row.original.urlCertificadoAtencion
            )
          }
          mostrarAtenciones={mostrarAtenciones}
        />
      ),
      disableFilters: true,
      className: 'w-2/12',
      atencion: true,
      orden: true,
    },
    {
      id: 'select',
      Header: (header) => {
        return (
          <HeaderSeleccionarTabla
            data={header?.data ?? []}
            funcionBoton={exportarCSVOrdenesSeleccionadas}
            funcionSeleccion={(data) => {
              setSeleccionExportar(data)
            }}
            imagenBoton={botonDescargar}
            seleccion={seleccionExportar}
            listaOrdenes={datosTabla}
          />
        )
      },
      Cell: ({row}) => {
        return (
          <div className="w-full flex  justify-center items-center">
            <div className="w-4/12"></div>
            <div className="w-8/12 flex justify-center items-center">
              <CeldaSeleccionar
                row={row}
                seleccionados={seleccionExportar}
                esSeleccionadoUnCheckbox={(row) =>
                  esSeleccionadoUnCheckbox(row, 'descarga')
                }
                mostrarAtenciones={mostrarAtenciones}
              />
            </div>
          </div>
        )
      },
      disableFilters: true,
      className: 'w-1/12',
      atencion: true,
      orden: true,
    },
    {
      id: 'test',
      Header: (header) => {
        return (
          <HeaderSeleccionarTabla
            data={header?.data ?? []}
            funcionBoton={clickModuloImprimir}
            funcionSeleccion={(data) => {
              setSeleccionImprimir(data)
            }}
            imagenBoton={IconoImprimirActivado}
            seleccion={seleccionImprimir}
            listaOrdenes={datosTabla}
            deshabilitado={true}
          />
        )
      },
      Cell: ({row}) => {
        return (
          <div className="w-full flex  justify-center items-center">
            <div className="w-4/12"></div>
            <div className="w-8/12 flex justify-center items-center">
              <CeldaSeleccionar
                row={row}
                seleccionados={seleccionImprimir}
                esSeleccionadoUnCheckbox={(row) =>
                  esSeleccionadoUnCheckbox(row, 'imprimir')
                }
                mostrarAtenciones={mostrarAtenciones}
              />
            </div>
          </div>
        )
      },
      disableFilters: true,
      className: 'w-1/12',
      atencion: true,
      orden: false,
    },
  ]

  const history = useHistory()

  const contextoAplicacion = useContext(ContextoAplicacion)

  const clickModulo = async (row) => {
    const orden = {
      id: row.id,
      estadoId: 5,
    }
    ActualizarEstadoOrden(orden)
      .then(() => {
        funcionFiltrar()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const clickModuloimprimirUnicoDocumento = (urlCertificadoAtencion) => {
    if (urlCertificadoAtencion) {
      var win = window.open(urlCertificadoAtencion, '_blank')
      win.focus()
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <ModalConfirmacionEstandar
            texto={'EL CERTIFICADO NO HA SIDO GENERADO'}
          />
        ),
        botones: [
          {
            nombre: 'Volver',
            click: manejarCerrarModal,
          },
        ],
      })
    }
  }

  const clickModuloEditar = (row) => {
    history.push('/editarorden', {state: row})
  }

  const clickModuloVer = (row) => {
    history.push('/resultadospaciente', {state: row})
  }

  const clickModuloImprimir = () => {
    const url = imprimirSeleccion.data.map((element) => {
      return element.urlCertificadoAtencion
    })

    const nombreCertificado = imprimirSeleccion.data.map((element) => {
      return {
        atencionId: element?.atencionId ?? '',
        numeroDocumento: element?.usuario?.numeroDocumento ?? '',
      }
    })

    descargarPdfZip(url, nombreCertificado)
  }

  const columnasAtencion = () => {
    if (mostrarAtenciones) {
      return columnasTabla?.filter((columna) => columna?.atencion)
    } else {
      return columnasTabla?.filter((columna) => columna?.orden)
    }
  }

  return (
    <>
      <div className="w-full my-10 flex flex-wrap justify-center items-center px-5">
        <div className="w-full flex justify-start items-center">
          <div className="w-full flex justify-start items-center flex-wrap">
            <ComponenteConjuntoDeFiltros
              filtrosPorCrear={filtrosExternos}
              funcionBoton={funcionFiltrar}
              textoFiltrosPrimeraLinea={
                'Al buscar atenciones (certificados), puede usar el número de documento, el nombre o el apellido del paciente.'
              }
              textoFiltrosSegundaLinea={
                'Puede filtrar la tabla de abajo por centro de costos, estado y sede.'
              }
              mostrarTextosEnFiltros={mostrarAtenciones}
            />
          </div>
        </div>

        <TablaOrdenes
          columns={columnasAtencion()}
          data={datosTabla}
          usarSelected={false}
          funcionSeleccion={(data) => {
            setSeleccionExportar(data)
            setSeleccionImprimir(data)
          }}
          limpiarTodosLosFiltros={funcionLimpiarFiltros}
          habilitarDescargaMasiva={mostrarAtenciones}
        >
          <PaginacionConBackend
            totalPaginas={informacionPaginacion?.totalPaginas}
            paginaActual={informacionPaginacion?.paginaActual}
            funcionIrAPagina={funcionCambiarPagina}
            maximoDePaginasparaMostrar={10}
          />
        </TablaOrdenes>
      </div>
      <CSVLink {...csvReport} ref={csvLinkEl}></CSVLink>
    </>
  )
}

export default ListadoOrdenesYAtenciones
